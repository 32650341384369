<template>
  <div>
    <div class="d-flex mb-8">
      <div class="titulo-pagina">
        {{ tituloFormulario }}
      </div>
    </div>

    <v-form ref="form" class="row">
      <input-text
        v-model="form.nome"
        class="col-12 col-md-3"
        :label="$t('modulos.tipo_documento.formulario.nome')"
        obrigatorio
        :max="100"
        trim
      />
      <input-select
        v-model="form.classificacaoDocumento"
        class="col-12 col-md-3"
        :label="$t('modulos.tipo_documento.formulario.classificacao_documento')"
        :options="opcoes.classificacaoDocumento"
        obrigatorio
      />
      <input-text
        v-model="form.validade"
        class="col-12 col-md-2"
        :label="$t('modulos.tipo_documento.formulario.validade_dias')"
        type="number"
      />
      <input-text
        v-model="form.nomeArquivo"
        class="col-12 col-md-2"
        :label="$t('modulos.tipo_documento.formulario.nomeArquivo')"
        disabled
      />
      <input-file
        returns="base64"
        class="col-12 col-md-2"
        :placeholder="$t('modulos.tipo_comentario.formulario.anexar')"
        :image="form.anexo"
        @nome-imagem="nomeImagem"
        @handle-upload="handleUpload"
      />
      <input-textarea
        v-model="form.descricao"
        :max="100"
        class="col-12 col-md-12"
        :label="$t('modulos.tipo_documento.formulario.descricao')"
      />
    </v-form>

    <v-divider class="my-5" />
    <div class="d-flex align-center justify-end">
      <botao-padrao outlined color="secondary" class="mr-2" @click="cancelar">
        <v-icon>$mdiCancel</v-icon>
        {{ $t('geral.botoes.cancelar') }}
      </botao-padrao>
      <botao-padrao @click="confirmacaoSalvar">
        <v-icon>$mdiContentSaveOutline</v-icon>
        {{ $t('geral.botoes.salvar') }}
      </botao-padrao>
    </div>
  </div>
</template>
<script>
import EnumeradorService from '@common/services/cadastros/EnumeradorService';
import TipoDocumentoService from '@common/services/cadastros/TipoDocumentoService';
import { TipoDocumentoModel } from '@common/models/cadastros/TipoDocumentoModel';
import { InputFile } from '@components/inputs';
import helpers from '@common/utils/helpers';
export default {
  components: {
    InputFile,
  },
  props: ['id'],
  data() {
    return {
      valido: false,
      form: new TipoDocumentoModel({}),
      opcoes: {
        classificacaoDocumento: [],
      },
    };
  },
  computed: {
    tituloFormulario: function () {
      if (this.id) return this.$t('modulos.tipo_documento.titulos.editar');
      return this.$t('modulos.tipo_documento.titulos.novo');
    },
  },
  mounted() {
    this.listarClassificacaoDocumento();
    if (this.id) {
      helpers.redirecionarSemPermissao(this, 'TipoDocumento', 'Editar');
      this.buscar(this.id);
    }
    helpers.redirecionarSemPermissao(this, 'TipoDocumento', 'Inserir');

    this.$store.dispatch('Layout/alterarTituloPagina', this.tituloFormulario);
  },
  methods: {
    buscar: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      TipoDocumentoService.buscar(this.id)
        .then((res) => {
          this.form = new TipoDocumentoModel(res.data);
          this.valido = true;
        })
        .catch(() => {
          this.toastErro(this.$t('modulos.tipo_documento.erros.id_invalido'));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    confirmacaoSalvar: function () {
      this.confirmarSalvar().then(() => {
        this.salvar();
      });
    },
    salvar: function () {
      if (this.$refs.form) this.valido = this.$refs.form.validate();
      if (!this.valido) return;
      this.$store.dispatch('Layout/iniciarCarregamento');
      TipoDocumentoService.salvar(this.form)
        .then(() => {
          this.$router.push({ name: 'tipo-documento' });
          this.toastSucesso(this.$t(`modulos.tipo_documento.cadastro_sucesso`));
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    cancelar: function () {
      this.confirmarCancelar().then(() => {
        this.$router.push({ name: 'tipo-documento' });
      });
    },
    handleUpload: function (file) {
      this.form.anexo = file;
    },
    nomeImagem: function (nomeImagem) {
      this.form.nomeArquivo = nomeImagem;
    },
    listarClassificacaoDocumento: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      EnumeradorService.buscar('EnumClassificacaoDocumento')
        .then((res) => {
          this.opcoes.classificacaoDocumento = res;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
  },
};
</script>
