export class TipoDocumentoModel {
  constructor({
    id,
    nome,
    descricao,
    validade,
    nomeArquivo,
    anexo,
    classificacaoDocumento,
   }) {
    this.id = id;
    this.nome = nome;
    this.descricao = descricao;
    this.validade = validade;
    this.nomeArquivo = nomeArquivo;
    this.anexo = anexo;
    this.classificacaoDocumento = classificacaoDocumento;
  }
}
